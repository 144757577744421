import React from 'react';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { ComparativeTableProps } from './types';
import {
	CheckIcon,
	CrossIcon,
	TBody,
	TBodyCell,
	TBodyRow,
	TBodyRowHeader,
	TContainer,
	THead,
	THeadCell,
	THeadRow,
	TLayout,
	TLayoutContainer,
	TScrollMsg,
} from './style';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';

export const ComparativeTable = ({
	headers,
	rows,
	tableScrollLabel,
}: ComparativeTableProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const getColumnIcon = (columnValue: 'YES' | 'NO') =>
		columnValue === 'YES' ? <CheckIcon /> : <CrossIcon />;

	return (
		<TContainer>
			{isMobileView && (
				<TScrollMsg variant="light" size="S">
					{tableScrollLabel}
				</TScrollMsg>
			)}
			<TLayoutContainer>
				<TLayout>
					<THead>
						<THeadRow>
							{headers?.map(({ body: { text } }) => (
								<THeadCell key={text}>
									<Body size="L" variant="light">
										{text}
									</Body>
								</THeadCell>
							))}
						</THeadRow>
					</THead>
					<TBody>
						{rows?.map(
							({ body: { text }, other, ours }) => (
								<TBodyRow key={text}>
									<TBodyRowHeader scope="row">
										<Body size="L" variant="light">
											{text}
										</Body>
									</TBodyRowHeader>
									<TBodyCell>
										{getColumnIcon(other)}
									</TBodyCell>
									<TBodyCell>
										{getColumnIcon(ours)}
									</TBodyCell>
								</TBodyRow>
							),
						)}
					</TBody>
				</TLayout>
			</TLayoutContainer>
		</TContainer>
	);
};
