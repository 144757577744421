import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import clsx from 'clsx';

import { HeaderSC, CtaContainer } from './style';
import { HeaderProps } from './types';
import { HeaderSkeleton } from './skeleton';

import {
	activeKRLocales,
	activeIDLocales,
} from '@atlas/statics/i18n/config';
import getProductType from '@atlas/statics/services/getProductType';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { LocaleParser } from '@atlas/statics/lib/LocaleParser';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';

import { Link } from '@atlas/ui-library/components/atoms/Link';
import type { LinkInputProps } from '@atlas/ui-library/components/atoms/Link/types';
import { Title } from '@atlas/ui-library/components/atoms/FontScale/Title';
import { Wrapper } from '@atlas/ui-library/components/layout/Wrapper';
import { Logo } from '@atlas/ui-library/components/atoms/Logo';
import LocaleSwitcher from '@atlas/ui-library/components/molecules/LocaleSwitcher';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';

export const Header = ({
	image,
	linkLogo,
	title,
	variant,
	cta,
	isLoading = false,
}: HeaderProps) => {
	const [scrolled, setScrolled] = useState(false);

	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const { data: crmData } = useCrmData();
	if (!crmData) {
		throw new Error('CRM data not found');
	}

	const { product } = getProductType();
	const {
		productType: { availableLanguages },
	} = crmData;

	const hasLocales =
		availableLanguages
			?.map(({ locale }) => LocaleParser.short(locale))
			.filter((locale) => {
				if (product === 'southkorea')
					return activeKRLocales.includes(locale);
				if (product === 'indonesia')
					return activeIDLocales.includes(locale);
				else return true;
			}).length > 1;

	/**
	 * Adds & removes class to have Fixed Position Header (Bottom) on scrolling down
	 * @listens window#scroll
	 */
	const scrollListener = () => {
		setScrolled(window.scrollY >= 200);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollListener);
		return () => {
			window.removeEventListener('scroll', scrollListener);
		};
	}, []);

	if (isLoading)
		return <Skeleton renderSkeleton={() => <HeaderSkeleton />} />;

	return (
		<ThemeProvider theme={{ variant }}>
			<HeaderSC
				className={clsx('HeaderSC', { scrolled })}
				id="header">
				<Wrapper direction="">
					<HeaderLogo
						href={linkLogo}
						image={image}
						title={title}
						isScrolled={scrolled}
					/>
					{hasLocales && isMobileView && !scrolled ? (
						<LocaleSwitcher />
					) : (
						<HeaderCTA {...cta} />
					)}
				</Wrapper>
			</HeaderSC>
		</ThemeProvider>
	);
};

const HeaderLogo = ({
	href = '',
	image,
	title,
	isScrolled,
}: {
	href: string;
	isScrolled: boolean;
} & Pick<HeaderProps, 'image' | 'title'>) => {
	return (
		<div className="logo">
			<Link href={href} variant="unstyled">
				{image.src !== '' ? (
					<Logo image={image} isScrolled={isScrolled} />
				) : (
					<Title
						size="XL"
						variant="dark"
						headTag={title.headTag}>
						{title.text}
					</Title>
				)}
			</Link>
		</div>
	);
};

const HeaderCTA = ({ href, text, variant, size }: LinkInputProps) => {
	return (
		text &&
		href && (
			<CtaContainer>
				<Link variant={variant} size={size} href={href}>
					{text}
				</Link>
			</CtaContainer>
		)
	);
};
