import styled from 'styled-components';
import { BodyContent } from '@atlas/ui-library/components/atoms/FontScale/Body/style';
import {
	TitleContainer,
	TitleContent,
} from '@atlas/ui-library/components/atoms/FontScale/Title/style';
import { LinkContent } from '@atlas/ui-library/components/atoms/Link/style';

const CSSTransitionStyles = `
	.accordion-content-enter {
		overflow: hidden;
		max-height: 0;
	}

	.accordion-content-enter-active {
		max-height: 1000px;
		transition: max-height 0.4s linear;
	}

	.accordion-content-exit {
		max-height: 1000px;
	}

	.accordion-content-exit-active {
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.25s cubic-bezier(0, 1, 0, 1);
	}
`;

const AccordionArrow = styled.div`
	min-height: 32px;
	min-width: 32px;
`;

const AccordionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	padding: var(--spacing-_24) var(--spacing-_32);
	cursor: pointer;
	text-align: left;
	${TitleContainer} {
		margin: 0px;
		${TitleContent} {
			color: color-mix(
				in srgb,
				var(--color-basic-black)
					var(--opacity-emphasis-surface-high),
				transparent
			);
		}
	}
`;

const AccordionContentOpen = `
	padding: 0 var(--spacing-_32) var(--spacing-_24);
`;

const AccordionContentClosed = `
	padding: 0 var(--spacing-_32);
`;

const AccordionContent = styled.div<{ $isOpen: boolean }>`
	transition:
		padding-top 0.5s,
		padding-bottom 0.5s;
	${({ $isOpen }) =>
		$isOpen ? AccordionContentOpen : AccordionContentClosed}

	p {
		margin-bottom: 0;
		text-align: left;
	}

	${BodyContent} {
		color: color-mix(
			in srgb,
			var(--color-text-onSurface)
				var(--opacity-emphasis-surface-medium),
			transparent
		);
		& + ${LinkContent} {
			margin-top: 16px;
		}
	}
`;

const AccordionSC = styled.div`
	&:last-of-type {
		margin-bottom: var(--spacing-_32);
	}
	position: relative;
	margin: 5px 0 0 0;
	border: 1px solid var(--color-primary-_500);
	border-left-width: 8px;
	background-color: var(--color-basic-white);
	z-index: 0;
	${CSSTransitionStyles}
`;

export {
	AccordionSC,
	AccordionHeader,
	AccordionContent,
	AccordionArrow,
};
