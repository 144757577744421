import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { JumbotronSkeletonSC } from './style.skeleton';

export const JumbotronSkeleton = (props: IContentLoaderProps) => (
	<JumbotronSkeletonSC
		className="Jumbotron"
		speed={2}
		width={960}
		height={460}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" width="100%" height="100%" />
	</JumbotronSkeletonSC>
);

export default JumbotronSkeleton;
