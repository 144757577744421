import React from 'react';
import Image from 'next/image';
import { FiChevronDown } from 'react-icons/fi';
import {
	StepArrow,
	StepIcon,
	StepInfo,
	StepItem,
	StepsContent,
	StepsLayout,
} from './style';
import { StepsProps } from './types';
import { Title } from '@atlas/ui-library/components/atoms/FontScale/Title';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { StepsSkeleton } from './skeleton';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';

export const Steps = ({
	variant,
	addStep,
	arrowIcon,
	isLoading = false,
}: StepsProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	if (isLoading)
		return <Skeleton renderSkeleton={() => <StepsSkeleton />} />;

	return (
		<StepsContent>
			<StepsLayout>
				{addStep?.map(({ body, title, image }, index) => (
					<StepItem key={title.text} $variant={variant}>
						{image.src && (
							<StepIcon>
								<Image
									unoptimized
									src={image.src}
									alt={image?.alt}
									width={75}
									height={75}
								/>
							</StepIcon>
						)}
						<StepInfo $variant={variant}>
							<Title
								size="S"
								variant="light"
								headTag="h3">
								{title.text}
							</Title>
							<Body size="M" variant="light">
								{body.text}
							</Body>
						</StepInfo>
						{index + 1 < addStep.length && (
							<StepArrow $variant={variant}>
								{arrowIcon && arrowIcon?.src ? (
									<Image
										unoptimized
										src={arrowIcon.src}
										alt={arrowIcon?.alt}
										width={
											arrowIcon.width
												? arrowIcon.width
												: 71
										}
										height={
											arrowIcon.height
												? arrowIcon.height
												: 30
										}
									/>
								) : (
									<FiChevronDown
										color={
											'var(--color-primary-_500)'
										}
									/>
								)}
							</StepArrow>
						)}
					</StepItem>
				))}
			</StepsLayout>
		</StepsContent>
	);
};
