import React from 'react';
import { ThemeProvider } from 'styled-components';
import {
	FiAlertCircle,
	FiAlertOctagon,
	FiAlertTriangle,
	FiCheckCircle,
} from 'react-icons/fi';
import { BannerContent } from './style';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { BannerProps } from './types';

export const Banner = ({ variant, body: { text } }: BannerProps) => {
	return (
		<ThemeProvider theme={{ variant }}>
			<BannerContent>
				{
					{
						info: <FiAlertCircle />,
						success: <FiCheckCircle />,
						error: <FiAlertOctagon />,
						warning: <FiAlertTriangle />,
					}[variant]
				}
				<Body size={'L'} variant={'light'}>
					{text}
				</Body>
			</BannerContent>
		</ThemeProvider>
	);
};
