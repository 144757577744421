import styled, { css } from 'styled-components';
import {
	TitleContainer,
	TitleContent,
} from '@atlas/ui-library/components/atoms/FontScale/Title/style';
import { media } from '@atlas/ui-library/lib/breakpoints';
import { BodyContent } from '@atlas/ui-library/components/atoms/FontScale/Body/style';
import type { Variant } from './types';

const defaultStepItem = `
	justify-content: flex-start;
	align-items: center;
`;

const bigNumberStepItem = `
	justify-content: center;
	align-items: flex-start;
`;

const defaultStepInfo = css`
	${TitleContainer} {
		margin-bottom: var(--spacing-_8);
		${TitleContent} {
			text-align: left;
		}
	}
	${BodyContent} p {
		text-align: left;
		margin: 0;
		${media.smMax`
			font-size: var(--font-body-size-L-tablet);
		`}
	}
`;

const bigNumberStepInfo = css`
	${TitleContainer} {
		margin-bottom: var(--spacing-_4);
		${TitleContent} {
			color: var(--color-primary-_900);
			font-size: var(--font-title-size-XL-laptop);
			font-weight: bold;
			text-align: center;
		}
	}
	p {
		text-align: center;
	}
	a {
		font-weight: bold;
		text-decoration: underline;
		color: var(--color-text-onSurface);
	}
`;

const defaultStepArrow = css`
	position: absolute;
	top: 100%;
	width: 100%;
	text-align: center;
	${media.mdMin`
		top: auto;
		left: 100%;
		width: 50px;
	`}
	svg {
		font-size: 2em;
		${media.mdMin`
			transform: rotate(-90deg);
			`}
	}
	&:has(img) {
		${media.mdMin`
			transform: rotate(-90deg);
			`}
	}
`;

const bigNumberStepArrow = `
	display: none;
`;

const StepsContent = styled.div`
	width: 100%;
	margin-bottom: var(--spacing-_16);
	${TitleContent} {
		text-align: center;
	}
`;

const StepsLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	${media.mdMin`
		flex-direction: row;
	`}
`;

const StepArrow = styled.div<{
	$variant: keyof typeof Variant;
}>`
	${({ $variant }) => $variant === 'default' && defaultStepArrow}
	${({ $variant }) => $variant === 'bigNumber' && bigNumberStepArrow}
`;

const StepInfo = styled.div<{
	$variant: keyof typeof Variant;
}>`
	${({ $variant }) => $variant === 'default' && defaultStepInfo}
	${({ $variant }) => $variant === 'bigNumber' && bigNumberStepInfo}
`;

const StepItem = styled.div<{
	$variant: keyof typeof Variant;
}>`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 288px;
	${media.mdMin`
		width: auto;
	`}
	&:not(:last-child) {
		margin-bottom: 50px;
		${media.mdMin`
			flex: 1 1 288px;
			margin-bottom: 0px;
			margin-right: 50px;
		`}
	}
	&:last-child {
		${media.mdMin`
			margin-right: 25px;		
		`}
		${media.lgMin`
			${StepInfo} {
				// HACK: Sin ésto se generan un salto de línea que provoca la desmaquetación del step
				// @see https://leadtech.atlassian.net/browse/VISAS-49987?focusedCommentId=666819
				flex: none;
			}
		`}
	}
	${({ $variant }) => $variant === 'default' && defaultStepItem}
	${({ $variant }) => $variant === 'bigNumber' && bigNumberStepItem}
`;

const StepIcon = styled.div`
	width: 80px;
	height: 80px;
`;

export {
	StepsContent,
	StepItem,
	StepArrow,
	StepInfo,
	StepIcon,
	StepsLayout,
};
