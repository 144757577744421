import React from 'react';
import Image from 'next/image';
import { ThemeProvider } from 'styled-components';
import { JumbotronSC, JumbotronTextBox } from './style';
import { JumboProps } from './types';
import JumbotronLoader from './skeleton';

import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { Wrapper } from '@atlas/ui-library/components/layout/Wrapper';
import { Link } from '@atlas/ui-library/components/atoms/Link';
import { Title } from '@atlas/ui-library/components/atoms/FontScale/Title';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { isMobile, isTablet } from '@atlas/ui-library/lib/isMobile';

export const Jumbotron = ({
	backgroundImage,
	backgroundImageMd,
	backgroundImageXs,
	hasTextBoxBackground,
	hasCTAArrow,
	image,
	variant,
	title,
	cta,
	body,
	isLoading = false,
}: JumboProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);
	const isTabletView = isTablet(windowWidth);

	const src = isMobileView
		? backgroundImageXs
		: isTabletView
		  ? backgroundImageMd
		  : backgroundImage;

	if (isLoading)
		return <Skeleton renderSkeleton={() => <JumbotronLoader />} />;

	return (
		<ThemeProvider theme={{ variant }}>
			<JumbotronSC className="Jumbotron">
				{backgroundImage && (
					<>
						{/* // NOTE: curently not used but keep it until we're sure. */}
						{/* <ImageFilter /> */}
						<Image
							unoptimized
							className="backgroundImage"
							src={src || backgroundImage}
							fill
							priority
							quality={100}
							alt=""
						/>
					</>
				)}
				<Wrapper direction="">
					{/* Extra Image in Row Variant */}
					{image?.src && (
						<div className="imageContainer">
							<Image
								unoptimized
								src={image.src}
								alt={image.alt ?? ''}
								width={image.width ?? '250'}
								height={image.height ?? '250'}
							/>
						</div>
					)}

					{/* Main content */}
					<JumbotronTextBox
						$hasTextBoxBackground={hasTextBoxBackground}>
						{title.text && (
							<Title
								size="L"
								variant={title.variant}
								headTag={title.headTag}>
								{title.text}
							</Title>
						)}
						{body.text && (
							<Body
								size={body.size}
								variant={body.variant}>
								{body.text}
							</Body>
						)}
						{cta.text && cta.href && (
							<Link
								variant={cta.variant}
								size={cta.size}
								href={cta.href}
								hasArrow={hasCTAArrow}>
								{cta.text}
							</Link>
						)}
					</JumbotronTextBox>
				</Wrapper>
			</JumbotronSC>
		</ThemeProvider>
	);
};
