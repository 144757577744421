import { useState } from 'react';
import { useTranslations } from 'next-intl';
import { base64Encode } from '@atlas/statics/lib/base64Encoder';
import type { Country } from './types';

export const useCountryCards = ({
	countries,
}: {
	countries: Country[];
}) => {
	const t = useTranslations();

	const sortedCountries = [...countries].sort((a, b) => {
		const nameA = t(base64Encode(a.name)).toUpperCase();
		const nameB = t(base64Encode(b.name)).toUpperCase();
		return nameA.localeCompare(nameB);
	});

	const [dataFiltered, setFilterContext] =
		useState<Country[]>(sortedCountries);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const isFiltered =
		sortedCountries.length > 0 &&
		dataFiltered.length < sortedCountries.length;

	const hasFilterResults = dataFiltered.length > 0;

	const shouldExpandFilter =
		!isCollapsed || dataFiltered.length === 0 || isFiltered;

	/**
	 * Toggles country list view between collapsed / entire list.
	 * @listens Button.onClickEvent - View all / View Less
	 * @fires CountryCards#setIsCollapsed - State setter
	 */
	const toggle = ({ target }: any) => {
		setIsCollapsed((wasCollapsed) => !wasCollapsed);
		if (!isCollapsed) {
			const targetTopPosition =
				target.parentElement.getBoundingClientRect().top +
				(window.scrollY - 400);
			scrollTo(0, targetTopPosition);
		}
	};

	return {
		dataFiltered,
		setFilterContext,
		countries,
		isFiltered,
		hasFilterResults,
		shouldExpandFilter,
		isCollapsed,
		toggle,
	};
};
