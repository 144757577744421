import { breakpoints } from '../breakpoints';

/**
 *	isMobile()
 *
 * @param windowWidth Current width of the browser window
 * @param mobileLimit Limit size of the mobile view
 * @returns true if the window width is less than the mobile limit and
 * 			greater than 0, false otherwise
 */
export const isMobile = (
	windowWidth: number,
	mobileLimit: string = breakpoints.TABLET_PORTRAIT_MIN_WIDTH,
) => windowWidth < parseInt(mobileLimit) && windowWidth > 0;

export const isTablet = (windowWidth: number) =>
	windowWidth >= parseInt(breakpoints.TABLET_PORTRAIT_MIN_WIDTH) &&
	windowWidth <= parseInt(breakpoints.TABLET_PORTRAIT_MAX_WIDTH) &&
	windowWidth > 0;
