import React, { SyntheticEvent } from 'react';
import Image from 'next/image';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import type { LogoProps } from './types';

export const Logo = ({ image, isScrolled }: LogoProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);
	const { src, alt, title, width, height } = image;

	const replaceSizeSuffix = (from: string, to: string) =>
		src.toString().replace(from, to);

	const getImageSrc = () => {
		if (isMobileView) {
			const xsLogo = replaceSizeSuffix('lg', 'xs');
			if (isScrolled)
				return xsLogo.replace('.webp', '_scrolled.webp');
			return xsLogo;
		} else {
			return replaceSizeSuffix('xs', 'lg');
		}
	};

	const showFallback = (
		e: SyntheticEvent<HTMLImageElement, Event>,
	) => {
		(e.target as HTMLImageElement).src = isMobileView
			? replaceSizeSuffix('lg', 'xs')
			: (src as string);
	};

	return (
		<Image
			unoptimized
			src={getImageSrc()}
			alt={alt}
			title={title}
			width={isMobileView ? '110' : width}
			height={isMobileView ? '40' : height}
			quality={100}
			onError={showFallback}
		/>
	);
};
