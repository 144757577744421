import React from 'react';
import { ThemeProvider } from 'styled-components';
import {
	GridSC,
	IconSC,
	TitleSC,
	DescriptionSC,
	FootNoteSC,
} from './style';
import { InfoBoxProps } from './types';
import InfoBoxSkeleton from './skeleton';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';

export const InfoBox = ({
	variant = 'default',
	direction = 'row',
	image,
	body,
	title,
	isLoading = false,
}: InfoBoxProps) => {
	const showAsCard = variant === 'card';

	if (isLoading) {
		return (
			<Skeleton
				renderSkeleton={() => <InfoBoxSkeleton />}
				justifyContent="start"
			/>
		);
	}

	//TODO: evaluate if it's possible to remove showAsCard if it's not used
	return (
		<ThemeProvider theme={{ variant, direction }}>
			<GridSC>
				<IconSC
					src={image.src as string}
					alt={image.alt}
					title={image.title}
					width={image.width ?? 35}
					height={image.height ?? 35}
				/>
				{!showAsCard && (
					<TitleSC
						size="S"
						variant={title.variant}
						headTag={title.headTag}
						text={title.text}
					/>
				)}
				<DescriptionSC variant={body.variant} size={body.size}>
					{body.text}
				</DescriptionSC>
				{showAsCard && (
					<FootNoteSC
						size="XS"
						variant={title.variant}
						headTag={title.headTag}>
						{title.text}
					</FootNoteSC>
				)}
			</GridSC>
		</ThemeProvider>
	);
};

export default InfoBox;
