'use client';
import React, { useEffect, useRef, useState } from 'react';
import {
	CurrentLocale,
	LocaleItem,
	LocaleList,
	LocaleSwitcherContainer,
} from './style';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLocale } from 'next-intl';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';
import getProductType from '@atlas/statics/services/getProductType';
import { LocaleParser } from '@atlas/statics/lib/LocaleParser';
import {
	activeIDLocales,
	activeKRLocales,
	localeNames,
	localesByRegion,
} from '@atlas/statics/i18n/config';

/**
 * @todo
 *  - NOTE: FIXME: Link 'locale' prop does not prepend correct locale on href
 *    so we build it manually.
 */
export default function LocaleSwitcher() {
	const currentLocale = useLocale();
	const pathname = usePathname();
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const localeSwitchRef = useRef<HTMLDivElement | null>(null);

	const { data: crmData } = useCrmData();
	if (!crmData) {
		throw new Error('CRM data not found');
	}

	const { product } = getProductType();
	const {
		productType: { availableLanguages },
	} = crmData;

	const getLocaleName = (locale: string): string => {
		const localeCode = localesByRegion.includes(locale)
			? locale
			: LocaleParser.short(locale);
		return localeNames[localeCode];
	};

	const getSlugFromUrl = (): string => pathname?.split('/')[2] || '';

	const expand = () => setIsOpen(true);

	const close = () => setIsOpen(false);

	const checkIfClickedOutside = (e: { target: any }) => {
		if (
			isOpen &&
			localeSwitchRef.current &&
			!localeSwitchRef.current.contains(e.target)
		) {
			close();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener(
				'mousedown',
				checkIfClickedOutside,
			);
		};
	}, [isOpen]);

	return (
		<LocaleSwitcherContainer ref={localeSwitchRef}>
			<CurrentLocale onClick={isOpen ? close : expand}>
				<Body size="L" variant="light">
					{getLocaleName(currentLocale)}
				</Body>
				{isOpen ? (
					<MdArrowDropUp size={'24px'} />
				) : (
					<MdArrowDropDown size={'24px'} />
				)}
			</CurrentLocale>
			<LocaleList $isOpen={isOpen} $isMobile={isMobileView}>
				{availableLanguages
					?.filter(({ locale }) => {
						const localeShortCode =
							LocaleParser.short(locale);
						if (currentLocale === localeShortCode)
							return false;
						// HACK: VISAS-49352
						if (product === 'southkorea') {
							return activeKRLocales.includes(
								localeShortCode,
							);
							// HACK: VISAS-59868
						} else if (product === 'indonesia') {
							return activeIDLocales.includes(
								localeShortCode,
							);
						} else {
							return true;
						}
					})
					.map(({ locale }) => {
						return (
							<LocaleItem key={locale}>
								<Link
									href={`/${LocaleParser.short(
										locale,
									)}/${getSlugFromUrl()}`}>
									<Body size="L" variant="light">
										{getLocaleName(locale)}
									</Body>
								</Link>
							</LocaleItem>
						);
					})}
			</LocaleList>
		</LocaleSwitcherContainer>
	);
}
