import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import {
	AccordionSC,
	AccordionContent,
	AccordionHeader,
	AccordionArrow,
} from './style';
import { AccordionProps } from './types';
import {
	Title,
	Body,
} from '@atlas/ui-library/components/atoms/FontScale';
import { Link } from '@atlas/ui-library/components/atoms/Link';
import AccordionSkeleton from './skeleton';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';

export const Accordion = ({
	addQuestion,
	isLoading = false,
}: AccordionProps) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(0);

	return (
		<>
			<div id="accordion">
				{addQuestion?.map(({ body, title, element }, index) => {
					const [questionButton] = element ?? [];
					const isOpen = index === activeIndex;

					const toggleQuestion = () => {
						setActiveIndex(isOpen ? null : index);
					};

					if (isLoading)
						return (
							<Skeleton
								key={`${title.text}_${index}`}
								renderSkeleton={() => (
									<AccordionSkeleton order={index} />
								)}
							/>
						);

					return (
						<AccordionSC key={title.text}>
							<AccordionHeader
								onClick={toggleQuestion}
								tabIndex={0}
								role="button"
								onKeyUp={toggleQuestion}>
								<Title
									size="XS"
									variant={
										(title.variant ??= 'lightBlack')
									}
									headTag="h3">
									{title.text}
								</Title>
								<AccordionArrow>
									{isOpen ? (
										<FiChevronUp
											color="var(--color-primary-_500)"
											size="32px"
										/>
									) : (
										<FiChevronDown
											color="var(--color-primary-_500)"
											size="32px"
										/>
									)}
								</AccordionArrow>
							</AccordionHeader>

							<AccordionContent $isOpen={isOpen}>
								<CSSTransition
									in={isOpen}
									timeout={250}
									classNames="accordion-content"
									unmountOnExit>
									<>
										<Body size="L" variant="light">
											{body.text}
										</Body>
										{questionButton && (
											<Link {...questionButton} />
										)}
									</>
								</CSSTransition>
							</AccordionContent>
						</AccordionSC>
					);
				})}
			</div>
		</>
	);
};
